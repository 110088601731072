import { AuthClient } from '@toasttab/frontend-authentication'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { api } from '../../api'
import { DepotCustomProps } from '../../../packages/depot-banquet-props/src'
import { populateUserEnvironments } from './manageUserStatusInSecondaryEnv'

export async function getGlobalCustomProps(
  auth: AuthClient,
  apolloClient: ApolloClient<NormalizedCacheObject>
): Promise<DepotCustomProps> {
  const partner = await api.getPartnerForUser(apolloClient)
  const permissions = await api.getPermissions([partner.guid], apolloClient)

  const userEnvironments = await populateUserEnvironments(
    auth.userInfo.guid,
    partner
  )

  return {
    partner,
    auth,
    permissions,
    userEnvironments
  }
}
