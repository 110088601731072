import { gql } from '@apollo/client'

export const GET_SECONDARY_ENV_PARTNER_FOR_USER = gql`
  query getSecondaryEnvPartnerForUser {
    getSecondaryEnvPartnerForUser {
      guid
      name
    }
  }
`
