import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { Partner } from '../../../packages/depot-banquet-props/src'
import { GET_SECONDARY_ENV_PARTNER_FOR_USER } from '../queries/getSecondaryEnvPartnerForUser'

const querySecondaryEnvPartnerForUser = async ({
  query
}: ApolloClient<NormalizedCacheObject>) => {
  return query<{ getSecondaryEnvPartnerForUser: Partner }>({
    query: GET_SECONDARY_ENV_PARTNER_FOR_USER
  }).then((response) => response.data.getSecondaryEnvPartnerForUser)
}

export const getSecondaryEnvPartnerForUser = async (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return querySecondaryEnvPartnerForUser(apolloClient).then(
    (partner) => partner
  )
}
