import { gql } from '@apollo/client'

export const GET_USER = gql`
  query getUserQuery($userGuid: ID!, $partnerGuid: ID!) {
    getUser(userGuid: $userGuid, partnerGuid: $partnerGuid) {
      guid
      username
      firstName
      lastName
      chosenName
      createdDate
      status
      roles {
        partnerGuid
        roles
      }
    }
  }
`
