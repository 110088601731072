import { getPartnerForUser } from './getPartnerForUser/getPartnerForUser'
import { getPermissions } from './getPermissions/getPermissions'
import { getSecondaryEnvPartnerForUser } from './manageUserInSecondaryEnv/getSecondaryEnvPartnerForUser'
import { createSecondaryEnvUser } from './manageUserInSecondaryEnv/createSecondaryEnvUser'
import { getMainEnvUserInfo } from './getUsers/getUser'

export const api = {
  getPartnerForUser,
  getPermissions,
  getMainEnvUserInfo,
  getSecondaryEnvPartnerForUser,
  createSecondaryEnvUser
}
