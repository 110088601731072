import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { GET_USER } from '../queries/getUser'

export interface DepotUser {
  guid: string
  username: string
  firstName: string
  lastName: string
  chosenName: string
  createdDate: string
  status: string
  roles: [RoleInfo]
}

interface RoleInfo {
  partnerGuid: string
  roles: [string]
}

export const getMainEnvUserInfo = async (
  partnerGuid: string,
  userGuid: string,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return queryUser(partnerGuid, userGuid, apolloClient).then((user) => {
    return user
  })
}

const queryUser = async (
  partnerGuid: string,
  userGuid: string,
  { query }: ApolloClient<NormalizedCacheObject>
) => {
  return query<{ getUser: DepotUser }>({
    query: GET_USER,
    variables: { partnerGuid, userGuid },
    context: {
      headers: {
        'toast-organization-guid': partnerGuid
      }
    }
  }).then((response) => {
    return response.data.getUser
  })
}
