import { ApolloClient } from '@apollo/client/core'
import { CREATE_SECONDARY_ENV_USER } from '../mutations/createSecondaryEnvUser'

export interface SecondaryEnvEntity {
  guid: string
  username: string
  firstName: string
  lastName: string
  partnerGuid: string
}

const mutationCreateUserInSecondaryEnvironment = async (
  apolloClient: ApolloClient<any>,
  user: SecondaryEnvEntity
) => {
  return apolloClient
    .mutate({
      mutation: CREATE_SECONDARY_ENV_USER,
      variables: {
        user
      }
    })
    .then((response) => response.data.createSecondaryEnvUser)
}

export const createSecondaryEnvUser = async (
  apolloClient: ApolloClient<any>,
  user: SecondaryEnvEntity
) => {
  return mutationCreateUserInSecondaryEnvironment(apolloClient, user).then(
    (response) => response
  )
}
